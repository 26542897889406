import { formatISODateTime, formatISODateTimeToTimeStamp } from "../formatters";
import { stringFrom } from "../parsing";

export function formatActivityTags(tag) {
  return {
    id: stringFrom(tag.id),
    createdTimestamp: formatISODateTimeToTimeStamp(stringFrom(tag.created_on)),
    createdOn: formatISODateTime(stringFrom(tag.created_on)),
    name: stringFrom(tag.name),
  };
}

export function formatActivityMasterItems(item) {
  return {
    id: stringFrom(item.id),
    createdTimestamp: formatISODateTimeToTimeStamp(stringFrom(item.created_on)),
    createdOn: formatISODateTime(stringFrom(item.created_on)),
    name: stringFrom(item.name),
    fkActivityTag: stringFrom(item.activity_tag_details.name),
    fkActivityTagId: stringFrom(item.activity_tag_details.id),
  };
}
