import {
  addDaysToDate,
  formatDateString,
  formatDateToTimestamp,
  formatISODateTime,
  formatISODateTimeToTimeStamp,
  getFileCategory,
  getLatestStatus,
  stringSentenceCase,
} from "../formatters";
import {
  stringFrom,
  arrayFrom,
  isKeyPresent,
  floatFrom,
  intFrom,
  boolFrom,
} from "../parsing";
import store from "@/store";
export function formatProjectsData(project) {
  return {
    id: stringFrom(project.id),
    createdTimestamp: formatISODateTimeToTimeStamp(
      stringFrom(project.created_on)
    ),
    createdOn: formatISODateTime(stringFrom(project.created_on)),
    projectCode: stringFrom(project.project_code),
    projectName: stringFrom(project.project_name),
    clientName: stringFrom(project.client_details.contact_details.name),
    city: stringFrom(project.address_details.city),
    stage: stringFrom(project.project_stage_details.name),
    stageId: stringFrom(project.project_stage_details.id),
    clientEmail: stringFrom(project.client_details.contact_details.email),
    clientPhone: stringFrom(project.client_details.contact_details.phone),
    clientId: stringFrom(project.fk_client),
    contactId: stringFrom(project.client_details.contact_details.id),
    legalName: stringFrom(project.client_details.legal_name),
    gstNumber: stringFrom(project.client_details.gst_number),
    lastUpdatedOn: formatISODateTime(stringFrom(project.last_updated_on)),
    description: stringFrom(project.description),
    budget: stringFrom(project.budget),
    state: stringFrom(project.address_details.state),
    addressLine1: stringFrom(project.address_details.address_line_1),
    addressLine2: stringFrom(project.address_details.address_line_2),
    addressId: stringFrom(project.address_details.id),
    pincode: stringFrom(project.address_details.pincode),
    location: stringFrom(project.address_details.location),
    isActive: boolFrom(project.is_active),
    stakeholders: arrayFrom(project.organization_contacts).map((user) => ({
      id: stringFrom(user.id),
      name: stringFrom(user.contact_details.name),
      email: stringFrom(user.contact_details.email),
      phone: stringFrom(user.contact_details.phone),
    })),
  };
}

export function formatProjectStagesData(projectStages) {
  return {
    id: stringFrom(projectStages.id),
    name: stringFrom(projectStages.name),
    type: stringFrom(projectStages.type),
    sortIndex: stringFrom(projectStages.sort_index),
  };
}

export function formatProjectFiles(file) {
  return {
    createdTimestamp: formatISODateTimeToTimeStamp(stringFrom(file.created_on)),
    createdOn: formatISODateTime(stringFrom(file.created_on)),
    id: stringFrom(file.id),
    name: stringFrom(file.name),
    section: stringFrom(file.section),
    projectId: stringFrom(file.fk_project),
    files: arrayFrom(file.project_asset_details).map((asset) => ({
      id: asset.id,
      assetData: {
        id: asset.asset_details.id,
        src: asset.asset_details.url,
        name: asset.asset_details.name,
        type: asset.asset_details.type,
        description: asset.asset_details.description,
      },
    })),
  };
}

export function formatProjectBoards(board) {
  return {
    createdTimestamp: formatISODateTimeToTimeStamp(
      stringFrom(board.created_on)
    ),
    createdOn: formatISODateTime(stringFrom(board.created_on)),
    id: stringFrom(board.id),
    name: stringFrom(board.board_name),
    files: arrayFrom(board.project_board_item_details).map((item) => ({
      id: stringFrom(item.id),
      title: stringFrom(item.moodboard_item_details.title),
      isActive: boolFrom(item.is_active),
      moodboardItemId: stringFrom(item.fk_moodboard_item),
      boardId: stringFrom(item.fk_project_board),
      assetData: {
        id: item.moodboard_item_details.asset_details.id,
        src: item.moodboard_item_details.asset_details.url,
        name: item.moodboard_item_details.asset_details.name,
        type: item.moodboard_item_details.asset_details.type,
        description: item.project_board_item_description,
      },
    })),
  };
}

export function formatProjectNotes(note) {
  return {
    createdTimestamp: formatISODateTimeToTimeStamp(stringFrom(note.created_on)),
    createdOn: formatISODateTime(stringFrom(note.created_on)),
    id: stringFrom(note.id),
    title: stringFrom(note.title),
    description: stringFrom(note.description),
    addedBy:
      store.getters.orgContacts.find(
        (item) => item.id === stringFrom(note.added_by)
      )?.name ||
      store.getters.clients.find(
        (item) => item.id === stringFrom(note.added_by)
      )?.clientName ||
      stringFrom(note.added_by),
    files: arrayFrom(note.notes_assets_details).map((item) => ({
      id: item.id,
      assetData: {
        id: item.asset_details.id,
        src: item.asset_details.url,
        name: item.asset_details.name,
        type: item.asset_details.type,
        description: item.asset_details.description,
      },
    })),
  };
}
export function formatProjectTasks(task, projectName = "") {
  const currentDate = new Date();
  const dueDate = new Date(stringFrom(task.due_date)); // Convert due date string to Date object

  // Set status to "Overdue" if current date exceeds due date
  let status = stringSentenceCase(stringFrom(task.status));
  if (currentDate > dueDate && task.completion_date === null) {
    status = "Overdue";
  }
  return {
    createdTimestamp: formatISODateTimeToTimeStamp(stringFrom(task.created_on)),
    createdOn: formatISODateTime(stringFrom(task.created_on)),
    id: stringFrom(task.id),
    projectId: stringFrom(task.fk_project),
    projectName: stringFrom(projectName), //USED IN TASKS FILTERING DONT REMOVE
    title: stringFrom(task.title),
    description: stringFrom(task.description),
    status: status,
    dueDate: formatDateString(stringFrom(task.due_date)),
    completionDate: formatDateString(stringFrom(task.completion_date)),
    createdById: stringFrom(task.fk_organization_contact),
    addedById: stringFrom(task.added_by),
    createdBy:
      store.getters.orgContacts.find(
        (item) => item.id === stringFrom(task.added_by)
      )?.name || stringFrom(task.added_by),
    assignedTo:
      store.getters.orgContacts.find(
        (item) => item.id === stringFrom(task.fk_organization_contact)
      )?.name || stringFrom(task.fk_organization_contact),
    assets: arrayFrom(task.task_assets_details).map((item) => ({
      id: item.id,
      assetData: {
        id: item.asset_details.id,
        src: item.asset_details.url,
        name: item.asset_details.name,
        type: item.asset_details.type,
        description: item.asset_details.description,
      },
    })),
  };
}
export function formatProjectFinancials(payment) {
  return {
    createdTimestamp: formatISODateTimeToTimeStamp(
      stringFrom(payment.created_on)
    ),
    createdOn: formatISODateTime(stringFrom(payment.created_on)),
    createdValue: stringFrom(payment.created_on),
    id: stringFrom(payment.id),
    description: stringFrom(payment.description),
    date: formatDateString(stringFrom(payment.date)),
    amount: floatFrom(payment.amount),
    paymentType: stringFrom(payment.payment_type),
    flag: stringFrom(payment.cr_dr_flag),
    image: payment.asset_details.url,
    paymentTypeId: stringFrom(payment.payment_type_details?.id) || "",
    paymentTypeName: stringFrom(payment.payment_type_details?.name) || "",
    transactionTypeId: stringFrom(payment.transaction_type_details?.id) || "",
    referenceNumber: stringFrom(payment.reference_number) || "",
    remark: stringFrom(payment.remark) || "",
    transactionTypeName:
      stringFrom(payment.transaction_type_details?.name) || "",
    vendor:
      store.getters.vendors.find(
        (item) =>
          item.orgVendorId === stringFrom(payment.fk_organization_vendor)
      )?.name || "",
    vendorId: stringFrom(payment.fk_organization_vendor),
    assetType: getFileCategory(payment.asset_details.type),
    assetData: payment.asset_details
      ? {
          id: payment.asset_details.id,
          src: payment.asset_details.url,
          name: payment.asset_details.name,
          type: payment.asset_details.type,
        }
      : {},
  };
}

export function formatProjectQuotes(quote) {
  const subTotal = quote.quote_item_details.reduce(
    (acc, item) => acc + item.client_rate * item.quantity - item.discount,
    0
  );

  const totalGst = quote.quote_item_details.reduce(
    (acc, item) =>
      acc + (item.client_rate * item.quantity - item.discount) * item.gst,
    0
  );

  const totalRate = subTotal + totalGst;

  const gstOnAdditionalCharges = quote.additional_charges_details.reduce(
    (totalGst, charge) => totalGst + charge.value * 0.18,
    0
  );

  const additionalChargesTotal = quote.additional_charges_details.reduce(
    (total, charge) => total + charge.value,
    0
  );

  const totalRateWithAdditionalCharges =
    totalRate + additionalChargesTotal + gstOnAdditionalCharges;
  const version = stringFrom(quote.version);
  return {
    createdTimestamp: formatISODateTimeToTimeStamp(
      stringFrom(quote.created_on)
    ),
    createdOn: formatISODateTime(stringFrom(quote.created_on)),
    id: stringFrom(quote.id),
    status: stringSentenceCase(stringFrom(quote.status)),
    code:
      version > 1
        ? stringFrom(quote.quote_number) + "-V" + stringFrom(quote.version)
        : stringFrom(quote.quote_number),

    totalRate: totalRateWithAdditionalCharges,
    subTotal: subTotal,
    gstCharges: totalGst + gstOnAdditionalCharges,
    paymentTermsId: stringFrom(quote.fk_payment_terms),
    version: version,
    additionalCharges: quote.additional_charges_details.map((charge) => ({
      name: charge.fk_additional_charges.header_name,
      id: charge.fk_additional_charges.id,
      rate: charge.value,
    })),
    items: quote.quote_item_details.map((item) => {
      return {
        id: item.id,
        name: item.item_details.name,
        description: item.description,
        quantity: item.quantity,
        uom: item.uom,
        rate: item.client_rate,
        gst: Math.round(item.gst * 100),
        discount: item.discount,
        total: item.client_rate * item.quantity - item.discount,
        itemDetails: item.item_details,
        section: item.quote_item_sections[0].id,
        sectionName: item.quote_item_sections[0].name,
        assetData: item.asset_details
          ? {
              id: item.asset_details.id,
              src: item.asset_details.url,
              name: item.asset_details.name,
              type: item.asset_details.type,
            }
          : {},
      };
    }),
    assets: arrayFrom(quote.quote_asset_details).map((quoteAsset) => ({
      id: quoteAsset.quote_asset_id,
      assetData: {
        id: quoteAsset.id,
        src: quoteAsset.url,
        name: quoteAsset.name,
        type: quoteAsset.type,
        description: quoteAsset.description,
      },
    })),
  };
}
export function formatProjectOrders(order) {
  const { subTotal, totalGst, totalRate } = order.order_item_details.reduce(
    (totals, item) => {
      const gstAmount = item.rate * item.gst * item.quantity; // GST for item quantity
      const itemTotalBeforeGst = item.rate * item.quantity; // Subtotal for item quantity before GST
      const itemTotalWithGst = itemTotalBeforeGst + gstAmount; // Total for item quantity with GST

      // Update accumulated totals
      totals.subTotal += itemTotalBeforeGst;
      totals.totalGst += gstAmount;
      totals.totalRate += itemTotalWithGst;
      return totals;
    },
    { subTotal: 0, totalGst: 0, totalRate: 0 }
  );

  // Calculate GST on additional charges
  const gstOnAdditionalCharges = order.additional_charges_details.reduce(
    (totalGst, charge) => totalGst + charge.value * 0.18,
    0
  );

  const additionalChargesTotal = order.additional_charges_details.reduce(
    (total, charge) => total + charge.value,
    0
  );

  const totalRateWithAdditionalCharges =
    totalRate + additionalChargesTotal + gstOnAdditionalCharges;

  return {
    createdTimestamp: formatISODateTimeToTimeStamp(
      stringFrom(order.created_on)
    ),
    createdOn: formatISODateTime(stringFrom(order.created_on)),
    id: stringFrom(order.id),
    orderType: stringFrom(order.order_type),
    status: stringSentenceCase(stringFrom(order.status)),
    code: stringFrom(order.order_number),
    vendorId: stringFrom(order.fk_organization_vendor),
    vendorName:
      store.getters.vendors.find(
        (item) => item.orgVendorId === stringFrom(order.fk_organization_vendor)
      )?.name || "",
    totalRate: totalRateWithAdditionalCharges,
    subTotal: subTotal,
    gstCharges: totalGst + gstOnAdditionalCharges,
    paymentTermsId: stringFrom(order.fk_payment_terms),
    additionalCharges: order.additional_charges_details.map((charge) => ({
      name: charge.fk_additional_charges.header_name,
      id: charge.fk_additional_charges.id,
      rate: charge.value,
    })),
    items: order.order_item_details.map((item) => {
      return {
        id: item.id,
        name: item.item_details.name,
        description: item.description,
        quantity: item.quantity,
        uom: item.uom,
        rate: item.rate,
        gst: Math.round(item.gst * 100),
        total: item.rate * item.quantity,
        itemDetails: item.item_details,
        assetData: item.asset_details
          ? {
              id: item.asset_details.id,
              src: item.asset_details.url,
              name: item.asset_details.name,
              type: item.asset_details.type,
            }
          : {},
      };
    }),
    assets: arrayFrom(order.vendor_order_asset_details).map((orderAsset) => ({
      id: orderAsset.vendor_order_asset_id,
      assetData: {
        id: orderAsset.id,
        src: orderAsset.url,
        name: orderAsset.name,
        type: orderAsset.type,
        description: orderAsset.description,
      },
    })),
  };
}

export function formatProjectActivities(activity, isClientProgress = false) {
  var endDate = addDaysToDate(
    stringFrom(activity.start_date),
    intFrom(activity.activity_days)
  );
  return {
    //Date and time
    createdOn: formatISODateTime(stringFrom(activity.created_on)),
    createdTimestamp: formatISODateTimeToTimeStamp(
      stringFrom(activity.created_on)
    ),
    startDate: formatDateString(stringFrom(activity.start_date)),
    startDateTimestamp: formatISODateTimeToTimeStamp(
      stringFrom(activity.start_date)
    ),
    endDate: endDate,
    endDateTimestamp: formatDateToTimestamp(endDate),
    //ID
    id: stringFrom(activity.id),
    //Other details
    description: stringFrom(activity.description),
    dependencyId: stringFrom(activity.dpendedncy_id),
    owner:
      isKeyPresent(activity, "organization_contact_details") &&
      isKeyPresent(activity.organization_contact_details, "fk_contact_details")
        ? stringFrom(
            activity.organization_contact_details.fk_contact_details.name
          )
        : "",
    createdBy:
      store.getters.orgContacts.find((item) => item.id === activity.added_by)
        ?.name || activity.added_by,

    progress: getLatestStatus(
      arrayFrom(
        isClientProgress
          ? activity.project_client_progress_status_details
          : activity.project_activity_status_details
      )
    ),

    ownerId: stringFrom(activity.fk_organization_contact),
    //ACTIVITY
    activityDays: intFrom(activity.activity_days),
    activityTagId: stringFrom(activity.activity_tag_details.id),
    activityTagName: stringFrom(activity.activity_tag_details.name),
    activityItemId: stringFrom(activity.activity_item_details.id),
    activityItemName: stringFrom(activity.activity_item_details.name),
    currentProgress: getLatestStatus(
      arrayFrom(
        isClientProgress
          ? activity.project_client_progress_status_details
          : activity.project_activity_status_details
      )
    ),
    //Updates
    activityStatusUpdates: isKeyPresent(
      activity,
      isClientProgress
        ? "project_client_progress_status_details"
        : "project_activity_status_details"
    )
      ? arrayFrom(
          isClientProgress
            ? activity.project_client_progress_status_details
            : activity.project_activity_status_details
        ).map((statusUpdate) => {
          return {
            activityItemName: stringFrom(activity.activity_item_details.name),
            createdTimestamp: formatISODateTimeToTimeStamp(
              stringFrom(statusUpdate.created_on)
            ),
            createdOnDate: formatISODateTime(
              stringFrom(statusUpdate.created_on)
            ),
            createdOn: formatISODateTime(
              stringFrom(statusUpdate.created_on),
              "dateTime"
            ),
            updateText: `Updated from ${Math.round(
              statusUpdate.last_status * 100
            )}% to ${Math.round(statusUpdate.current_status * 100)}%`,

            addedById: stringFrom(statusUpdate.added_by),
            createdBy:
              store.getters.orgContacts.find(
                (item) => item.id === stringFrom(statusUpdate.added_by)
              )?.name || stringFrom(statusUpdate.added_by),

            currentStatus: Math.round(
              floatFrom(statusUpdate.current_status * 100)
            ),
            lastStatus: Math.round(floatFrom(statusUpdate.last_status * 100)),
            remark: stringFrom(statusUpdate.remark),
            projectActivityStatusAssets: arrayFrom(
              isClientProgress
                ? statusUpdate.project_client_progress_status_assets
                : statusUpdate.project_activity_status_assets
            ).map((assetData) => {
              return {
                id: isClientProgress
                  ? statusUpdate.fk_project_client_progress
                  : statusUpdate.fk_project_activity,
                assetData: {
                  id: assetData.asset_details.id,
                  src: assetData.asset_details.url,
                  name: assetData.asset_details.name,
                  type: assetData.asset_details.type,
                  description: assetData.asset_details.description,
                },
              };
            }),
          };
        })
      : [],
  };
}

export function formatProjectManpower(item) {
  return {
    id: stringFrom(item.id),
    date: formatISODateTime(stringFrom(item.date)),
    createdTimestamp: formatISODateTimeToTimeStamp(stringFrom(item.created_on)),
    createdOn: formatISODateTime(stringFrom(item.created_on)),
    count: stringFrom(item.count),
    remark: stringFrom(item.remark),
    type: stringFrom(item.manpower_item_details.name),
    typeId: stringFrom(item.manpower_item_details.id),
    assets: arrayFrom(item.project_manpower_asset_details).map((item) => {
      return {
        id: item.asset_details.id,
        src: item.asset_details.url,
        name: item.asset_details.name,
        type: item.asset_details.type,
        description: "",
      };
    }),
  };
}
