// src/store/index.js
import { createStore } from "vuex";
import Cookies from "js-cookie";
import router from "@/router";
import { apiRequestHandler } from "@/services/rest-api-manager";
import { formatClientsData } from "@/utilities/data-mapping/clients";
import {
  formatContacts,
  formatVendor,
} from "@/utilities/data-mapping/contacts";
import {
  formatProjectsData,
  formatProjectStagesData,
} from "@/utilities/data-mapping/projects";
import { formatMoodboardGroups } from "@/utilities/data-mapping/moodboard";
import {
  formatActivityMasterItems,
  formatActivityTags,
} from "@/utilities/data-mapping/activities";
import { formatManpowerItems } from "@/utilities/data-mapping/manpower";
import { encodeJsonWithKey } from "@/utilities/encryption";
import { formatOrderData } from "@/utilities/data-mapping/settings";
import { showFailureAlert } from "@/services/rest-api-manager";
import { setUserPermissions } from "@/services/auth";
import { formatAdditionalCharges } from "@/utilities/data-mapping/misc";
//STOTE VALUES
const store = createStore({
  state: {
    user: { email: "", phone: "", name: "" },
    permissions: {},
    snackbarAlert: {},
    alert: {},
    clients: [],
    projectStages: [],
    orgContacts: [],
    authGroups: [],
    moodboardData: [],
    projectsData: [],
    projectFiles: [],
    activityTags: [],
    manpowerTypes: [],
    activityItems: [],
    orderItems: [],
    vendors: [],
    selectedProject: {},
    termsAndConditions: [],
    sections: [],
    additionalCharges: { quotes: [], orders: [] },
    paymentTypes: [],
    transactionTypes: [],
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },
    showSnackbar(state, snackbarAlert) {
      state.snackbarAlert = snackbarAlert;
    },
    showAlert(state, alert) {
      state.alert = alert;
    },
    setClientData(state, clients) {
      state.clients = clients;
    },

    setOrgContactsData(state, orgContacts) {
      state.orgContacts = orgContacts;
    },
    setProjectStagesData(state, projectStages) {
      state.projectStages = projectStages;
    },
    setAuthGroupsData(state, authGroups) {
      state.authGroups = authGroups;
    },
    setMoodboardData(state, moodboardData) {
      state.moodboardData = moodboardData;
    },
    setProjectsData(state, projectsData) {
      state.projectsData = projectsData;
    },
    setProjectFiles(state, projectFiles) {
      state.projectFiles = projectFiles;
    },
    setActivityTagsData(state, activityTags) {
      state.activityTags = activityTags;
    },
    setManpowerTypesData(state, manpowerTypes) {
      state.manpowerTypes = manpowerTypes;
    },
    setActivityItemsData(state, activityItems) {
      state.activityItems = activityItems;
    },
    setOrderItems(state, orderItems) {
      state.orderItems = orderItems;
    },
    setVendorsData(state, vendors) {
      state.vendors = vendors;
    },
    setSelectedProject(state, selectedProject) {
      state.selectedProject = selectedProject;
    },
    setTermsAndConditionsData(state, termsAndConditions) {
      state.termsAndConditions = termsAndConditions;
    },
    setSectionsData(state, sections) {
      state.sections = sections;
    },
    setAdditionalCharges(state, additionalCharges) {
      state.additionalCharges = additionalCharges;
    },
    setPaymentTypes(state, paymentTypes) {
      state.paymentTypes = paymentTypes;
    },
    setTransactionTypes(state, transactionTypes) {
      state.transactionTypes = transactionTypes;
    },
  },
  actions: {
    setUser({ commit }, user) {
      if (Object.keys(user).length > 0) {
        Cookies.set("token", user.token);
        Cookies.set("organizationId", user.organization_id);
        localStorage.setItem(
          "userData",
          encodeJsonWithKey(user, process.env.VUE_APP_USER_DATA_ENCRYPTION_KEY)
        ); //USED FOR PERSISTING THE USER DATA FOR THE SESSION
        commit("setUser", user);
        setUserPermissions();
      } else {
        // Remove cookies if the user object is empty
        router.push({ name: "Login" });
        Cookies.remove("token");
        Cookies.remove("organizationId");
        localStorage.setItem("userData", null);
        commit("setUser", {});
        commit("setPermissions", {});
        commit("setClientData", []);
        commit("setOrgContactsData", []);
        commit("setProjectStagesData", []);
        commit("setAuthGroupsData", []);
        commit("setMoodboardData", []);
        commit("setProjectsData", []);
        commit("setProjectFiles", []);
        commit("setActivityTagsData", []);
        commit("setManpowerTypesData", []);
        commit("setActivityItemsData", []);
        commit("setOrderItems", []);
        commit("setVendorsData", []);
        commit("setSelectedProject", {});
        commit("setTermsAndConditionsData", []);
        commit("setSectionsData", []);
        commit("setAdditionalCharges", { quotes: [], orders: [] });
        commit("setSectionsData", []);
        commit("setSectionsData", []);
      }
    },
    showSnackbar({ commit }, snackbarAlert) {
      commit("showSnackbar", snackbarAlert);
    },
    setPermissions({ commit }, permissions) {
      commit("setPermissions", permissions);
    },
    showAlert({ commit }, alert) {
      commit("showAlert", alert);
    },

    async setActivityItemsData({ commit }, activityItems = null) {
      if (activityItems == null) {
        var response = await apiRequestHandler("fetchActivityMaster");
        var data = [];
        if (response.success) {
          try {
            data = [];
            response.data.forEach((item) => {
              data.push(formatActivityMasterItems(item));
            });
            data = data.filter(
              (item) => item.id !== "44f9a4cf-d5d5-473f-8467-0ac457932ede" // Activity tag id for all activities
            );
            commit("setActivityItemsData", data);
          } catch (error) {
            commit("setActivityItemsData", []);
            showFailureAlert(error);
          }
        } else {
          commit("setActivityItemsData", []);
          showFailureAlert(
            "An error occurred while fetching activity master data."
          );
        }
      } else {
        commit("setActivityItemsData", activityItems);
      }
    },
    async setClientData({ commit }, clientsData = null) {
      if (clientsData == null) {
        var response = await apiRequestHandler("getAllClients");
        var data = [];
        if (response.success) {
          try {
            data = [];
            response.data.forEach((item) => {
              data.unshift(formatClientsData(item));
            });
            commit("setClientData", data);
          } catch (error) {
            commit("setClientData", []);
            showFailureAlert(error);
          }
        } else {
          commit("setClientData", []);
          showFailureAlert("An error occurred while fetching client data.");
        }
      } else {
        commit("setClientData", clientsData);
      }
    },
    async setOrgContactsData({ commit }, orgContacts = null) {
      if (orgContacts == null) {
        var response = await apiRequestHandler("fetchOrgContacts");
        var data = [];
        if (response.success) {
          try {
            data = [];
            response.data.forEach((item) => {
              data.push(formatContacts(item));
            });
            commit("setOrgContactsData", data);
          } catch (error) {
            commit("setOrgContactsData", []);
            showFailureAlert(error);
          }
        } else {
          commit("setOrgContactsData", []);
          showFailureAlert(
            "An error occurred while fetching organization contacts. "
          );
        }
      } else {
        commit("setOrgContactsData", orgContacts);
      }
    },
    async setProjectStagesData({ commit }, projectStages = null) {
      if (projectStages == null) {
        var response = await apiRequestHandler("getProjectStages");

        var data = [];
        if (response.success) {
          try {
            data = [];
            response.data.forEach((item) => {
              data.push(formatProjectStagesData(item));
            });
            commit("setProjectStagesData", data);
          } catch (error) {
            commit("setProjectStagesData", []);
            showFailureAlert(error);
          }
        } else {
          commit("setProjectStagesData", []);
          showFailureAlert("An error occurred while fetching project stages. ");
        }
      } else {
        commit("setProjectStagesData", projectStages);
      }
    },
    async setAuthGroupsData({ commit }, authGroups = null) {
      if (authGroups == null) {
        var response = await apiRequestHandler("fetchAuthGroups");

        if (response.success) {
          try {
            commit("setAuthGroupsData", response.data);
          } catch (error) {
            commit("setAuthGroupsData", []);
            showFailureAlert(error);
          }
        } else {
          commit("setAuthGroupsData", []);
          showFailureAlert("An error occurred while fetching auth groups. ");
        }
      } else {
        commit("setAuthGroupsData", authGroups);
      }
    },
    async setSectionsData({ commit }, sections = null) {
      if (sections == null) {
        var response = await apiRequestHandler("getQuoteSections");

        if (response.success) {
          try {
            commit("setSectionsData", response.data);
          } catch (error) {
            commit("setSectionsData", []);
            showFailureAlert(error);
          }
        } else {
          commit("setSectionsData", []);
          showFailureAlert("An error occurred while fetching quote sections. ");
        }
      } else {
        commit("setSectionsData", sections);
      }
    },
    async setTermsAndConditionsData({ commit }, termsAndConditions = null) {
      if (termsAndConditions == null) {
        var response = await apiRequestHandler("getPaymentTerms");

        if (response.success) {
          try {
            commit("setTermsAndConditionsData", response.data);
          } catch (error) {
            commit("setTermsAndConditionsData", []);
            showFailureAlert(error);
          }
        } else {
          commit("setTermsAndConditionsData", []);
          showFailureAlert(
            "An error occurred while fetching terms and conditions. "
          );
        }
      } else {
        commit("setTermsAndConditionsData", termsAndConditions);
      }
    },
    async setMoodboardData({ commit }, moodboardData = null) {
      if (moodboardData == null) {
        var response = await apiRequestHandler("fetchMoodboardItems");
        var data = [];
        if (response.success) {
          try {
            data = [];
            response.data.forEach((item) => {
              data.push(formatMoodboardGroups(item));
            });
            commit(
              "setMoodboardData",
              data.sort((a, b) => new Date(a.sortIndex) - new Date(b.sortIndex))
            );
          } catch (error) {
            commit("setMoodboardData", []);
            showFailureAlert(error);
          }
        } else {
          commit("setMoodboardData", []);
          showFailureAlert("An error occurred while fetching moodboard data. ");
        }
      } else {
        commit("setMoodboardData", moodboardData);
      }
    },
    async setProjectsData({ commit }, projectsData = null) {
      if (projectsData == null) {
        var response = await apiRequestHandler("getUserProjects", {
          id: store.getters.currentUser.organization_contact_id,
        });
        var data = [];
        if (response.success) {
          try {
            data = [];
            response.data.forEach((item) => {
              data.push(formatProjectsData(item));
            });
            commit("setProjectsData", data);
          } catch (error) {
            commit("setProjectsData", []);
            showFailureAlert(error);
          }
        } else {
          commit("setProjectsData", []);
          showFailureAlert("An error occurred while fetching projects. ");
        }
      } else {
        commit("setProjectsData", projectsData);
      }
    },
    async setManpowerTypesData({ commit }, manpowerTypes = null) {
      if (manpowerTypes == null) {
        var response = await apiRequestHandler("fetchManpowerItems");
        var data = [];
        if (response.success) {
          try {
            data = [];
            response.data.forEach((item) => {
              data.push(formatManpowerItems(item));
            });
            commit("setManpowerTypesData", data);
          } catch (error) {
            commit("setManpowerTypesData", []);
            showFailureAlert(error);
          }
        } else {
          commit("setManpowerTypesData", []);
          showFailureAlert("An error occurred while fetching manpower types. ");
        }
      } else {
        commit("setManpowerTypesData", manpowerTypes);
      }
    },
    async setActivityTagsData({ commit }, activityTags = null) {
      if (activityTags == null) {
        var response = await apiRequestHandler("fetchActivityTags");
        var data = [];
        if (response.success) {
          try {
            data = [];
            response.data.forEach((item) => {
              data.push(formatActivityTags(item));
            });
            commit("setActivityTagsData", data);
          } catch (error) {
            commit("setActivityTagsData", []);
            showFailureAlert(error);
          }
        } else {
          commit("setActivityTagsData", []);
          showFailureAlert("An error occurred while fetching activity tags. ");
        }
      } else {
        commit("setActivityTagsData", activityTags);
      }
    },
    async setOrderItems({ commit }, orderItems = null) {
      if (orderItems == null) {
        var response = await apiRequestHandler("fetchItems");
        var data = [];
        if (response.success) {
          try {
            data = [];
            response.data.forEach((item) => {
              data.push(formatOrderData(item));
            });
            commit("setOrderItems", data);
          } catch (error) {
            commit("setOrderItems", []);
            showFailureAlert(error);
          }
        } else {
          commit("setOrderItems", []);
          showFailureAlert("An error occurred while fetching items. ");
        }
      } else {
        commit("setOrderItems", orderItems);
      }
    },
    setProjectFiles({ commit }, projectFiles) {
      commit("setProjectFiles", projectFiles);
    },
    async setVendorsData({ commit }, vendors = null) {
      if (vendors == null) {
        try {
          var response = await apiRequestHandler("fetchOrgVendors");
          var data = [];
          if (response.success) {
            data = [];
            response.data.forEach((item) => {
              data.push(formatVendor(item));
            });
            commit("setVendorsData", data);
          } else {
            commit("setVendorsData", []);
            showFailureAlert(
              "Failed to fetch vendors. Please try again later."
            );
          }
        } catch (error) {
          commit("setVendorsData", []);
          showFailureAlert(error);
        }
      } else {
        commit("setVendorsData", vendors);
      }
    },
    setSelectedProject({ commit }, selectedProject) {
      commit("setSelectedProject", selectedProject);
    },
    async setAdditionalCharges({ commit }, additionalCharges = null) {
      if (additionalCharges == null) {
        var response = await apiRequestHandler("getAdditionalChargesList");

        if (response.success) {
          try {
            commit(
              "setAdditionalCharges",
              formatAdditionalCharges(response.data)
            );
          } catch (error) {
            commit("setAdditionalCharges", []);
            showFailureAlert(error);
          }
        } else {
          commit("setAdditionalCharges", []);
          showFailureAlert(
            "An error occurred while fetching additional charges."
          );
        }
      } else {
        commit("setAdditionalCharges", additionalCharges);
      }
    },

    async setPaymentTypes({ commit }, paymentTypes = null) {
      if (paymentTypes == null) {
        var response = await apiRequestHandler("getPaymentTypes");

        if (response.success) {
          try {
            commit("setPaymentTypes", response.data);
          } catch (error) {
            commit("setPaymentTypes", []);
            showFailureAlert(error);
          }
        } else {
          commit("setPaymentTypes", []);
          showFailureAlert("An error occurred while fetching payment types.");
        }
      } else {
        commit("setPaymentTypes", paymentTypes);
      }
    },

    async setTransactionTypes({ commit }, transactionTypes = null) {
      if (transactionTypes == null) {
        var response = await apiRequestHandler("getTransactionTypes");

        if (response.success) {
          try {
            commit("setTransactionTypes", response.data);
          } catch (error) {
            commit("setTransactionTypes", []);
            showFailureAlert(error);
          }
        } else {
          commit("setTransactionTypes", []);
          showFailureAlert(
            "An error occurred while fetching transaction types."
          );
        }
      } else {
        commit("setTransactionTypes", transactionTypes);
      }
    },
  },
  getters: {
    currentUser: (state) => state.user,
    permissions: (state) => state.permissions,
    snackbar: (state) => state.snackbarAlert,
    alert: (state) => state.alert,
    clients: (state) => state.clients,
    projects: (state) => state.projectsData,
    orgContacts: (state) => state.orgContacts,
    projectStages: (state) => state.projectStages,
    authGroups: (state) => state.authGroups,
    moodboardData: (state) => state.moodboardData,
    projectFiles: (state) => state.projectFiles,
    activityTags: (state) => state.activityTags,
    manpowerTypes: (state) => state.manpowerTypes,
    activityItems: (state) => state.activityItems,
    orderItems: (state) => state.orderItems,
    vendors: (state) => state.vendors,
    selectedProject: (state) => state.selectedProject,
    termsAndConditions: (state) => state.termsAndConditions,
    sections: (state) => state.sections,
    additionalCharges: (state) => state.additionalCharges,
    paymentTypes: (state) => state.paymentTypes,
    transactionTypes: (state) => state.transactionTypes,
  },
});

export default store;
