import store from "@/store";
export function getNavItems() {
  const permissions = store.getters.permissions.roles;
  return navItems.filter(
    (item) =>
      item.permission.length === 0 ||
      item.permission.some((permission) => permissions.includes(permission))
  );
}
const navItems = [
  {
    title: "Projects",
    icon: "mdi-floor-plan",
    permission: [],
  },
  {
    title: "Clients",
    icon: "mdi-account-group-outline",
    permission: ["viewClients", "allowFullAccess"],
  },
  {
    title: "My Tasks",
    icon: "mdi-clipboard-list-outline",
    permission: ["viewProjectTasks", "allowFullAccess"],
  },
  {
    title: "Attendance",
    icon: "mdi-account-check-outline",
    permission: [
      "allowFullAccess",
      "viewAttendance",
      "viewOrganizationManpowers",
    ],
  },
  {
    title: "Activities",
    icon: "mdi-rhombus-split-outline",
    permission: ["viewProjectActivities", "allowFullAccess"],
  },
  {
    title: "Logs",
    icon: "mdi-clipboard-text-clock-outline",
    permission: ["allowFullAccess"],
  },
  {
    title: "Settings",
    icon: "mdi-cog-outline",
    permission: ["allowFullAccess"],
  },
];
