<template>
  <v-dialog v-model="showDialog" max-width="400" scrollable>
    <v-card rounded="lg">
      <DialogHeading title="Reset Password" @close="$emit('close')" />
      <v-card-text>
        <v-form ref="form">
          <v-row no-gutters>
            <v-col cols="12">
              <p class="textFieldHeading">Current Password *</p>
              <v-text-field
                density="compact"
                v-model="currentPassword"
                :rules="[$validations.required]"
                variant="solo"
                flat
                :type="'password'"
                single-line
                prepend-inner-icon="mdi-key"
                class="customTextfield"
                autocomplete="current-password"
                :readonly="isApiExecuting"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <p class="textFieldHeading">New Password *</p>
              <v-text-field
                density="compact"
                flat
                :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                class="customTextfield"
                :type="showPassword ? 'text' : 'password'"
                v-model="newPassword"
                variant="solo"
                single-line
                autocomplete="new-password"
                prepend-inner-icon="mdi-key"
                @click:append-inner="showPassword = !showPassword"
                :rules="[
                  $validations.required,
                  $validations.minLength,
                  $validations.hasNumber,
                  $validations.hasUpperCaseLetter,
                  $validations.hasLowerCaseLetter,
                  $validations.hasSpecialCharacter,
                ]"
                :readonly="isApiExecuting"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <p class="textFieldHeading">Confirm Password *</p>
              <v-text-field
                density="compact"
                flat
                :append-inner-icon="
                  showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                "
                class="customTextfield"
                :type="showConfirmPassword ? 'text' : 'password'"
                v-model="confirmPassword"
                variant="solo"
                single-line
                autocomplete="new-password"
                prepend-inner-icon="mdi-key"
                @click:append-inner="showConfirmPassword = !showConfirmPassword"
                :rules="[
                  $validations.required,
                  $validations.minLength,
                  $validations.hasNumber,
                  $validations.hasUpperCaseLetter,
                  $validations.hasLowerCaseLetter,
                  $validations.hasSpecialCharacter,
                  (v) => v == newPassword || `Passwords don't match`,
                ]"
                :readonly="isApiExecuting"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <formActions @clicked="save" />
      <loadingIndicator v-if="isApiExecuting" />
    </v-card>
  </v-dialog>
</template>

<script>
import { apiRequestHandler } from "@/services/rest-api-manager";
import DialogHeading from "@/components/misc/dialogHeading.vue";
import formActions from "@/components/misc/formActions.vue";
import loadingIndicator from "@/components/misc/loadingIndicator.vue";
export default {
  name: "ResetPasswordForm",
  components: { DialogHeading, formActions, loadingIndicator },
  data: () => ({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
    isApiExecuting: false,
  }),
  props: {
    show: { type: Boolean, default: false },
  },
  computed: {
    showDialog: {
      get() {
        return this.show;
      },
      set(dialogValue) {
        if (!dialogValue) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    async save() {
      if (this.$refs.form.validate() && this.$refs.form.isValid) {
        try {
          this.isApiExecuting = true;
          var response = await apiRequestHandler("resetPassword", {
            old_password: this.currentPassword,
            new_password: this.newPassword,
            confirm_new_password: this.confirmPassword,
          });
          if (response.success) {
            this.$store.dispatch("showSnackbar", {
              show: true,
              text: "Password updated successfully!",
              color: "success",
            });
          } else {
            this.$store.dispatch("showSnackbar", {
              show: true,
              text: response.errorMessage.message,
              color: response.status,
            });
          }
        } catch (error) {
          this.$store.dispatch("showSnackbar", {
            show: true,
            text: error.message,
            color: "error",
          });
        } finally {
          this.isApiExecuting = false;
          this.showDialog = false;
        }
      }
    },
  },
};
</script>
